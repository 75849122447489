import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import CaseStudyCard from "../components/CaseStudyCard"
import SEO from "../components/seo"

const OurWorkPage = ({ data, location }) => {
  const our_work = data.ourWork.edges[0].node
  const case_studies = data.caseStudies.edges

  return (
    <Layout
      parallax={true}
      hero={
        <Hero
          image={our_work.hero_background}
          heading={our_work.hero_heading}
          subhead={our_work.hero_subhead}
        />
      }
    >
      <SEO title="Our Work" path={location.pathname} />
      <Container as="section">
        {case_studies
          .sort((a, b) => (a.node.slug > b.node.slug ? 1 : -1))
          .map(caseStudy => (
            <CaseStudyCard
              data={caseStudy.node}
              ctaUrl={`/our-work/${caseStudy.node.slug}/`}
              ctaLabel="View Project"
              className="mb-5 mobile p-5"
            />
          ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    ourWork: allButterPage(filter: { slug: { eq: "our-work" } }) {
      edges {
        node {
          hero_heading
          hero_subhead
          hero_background
        }
      }
    }
    caseStudies: allButterPage(filter: { page_type: { eq: "case_studies" } }) {
      edges {
        node {
          slug
          device_mockup
          hero_background
          logo
          title
          summary
        }
      }
    }
  }
`

export default OurWorkPage
